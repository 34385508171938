import { graphql } from 'gatsby';
import Link from '@components/Link';

import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '@atoms/Button/Button';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import Module from '@atoms/Module/Module';
import Footer from '@components/Footer/Footer';
import HeaderMinimal from '@components/HeaderMinimal/HeaderMinimal';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import Seo from '@components/seo';
import OrderedCard from '@components/Card/OrderedCard';
import VideoBackgroundWithContent from '@components/VideoBackgroundWithContent';
import { getDataByLang } from '@helpers/GlobalHelpers';
import { useLocale } from '@hooks';
import MarkdownText from '@atoms/MarkdownText/MarkdownText';
import { withLocale } from '@hocs';
import './CodeOfConduct.scss';

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: {
                eq: "code-of-conduct/code-of-conduct-header-mobile.png"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: {
                eq: "code-of-conduct/code-of-conduct-header-desktop.png"
            }
        ) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aepsyApiDe {
            getMarkdownContents(
                input: {
                    keys: [
                        "CODE_OF_CONDUCT1"
                        "CODE_OF_CONDUCT2"
                        "CODE_OF_CONDUCT3"
                    ]
                    locales: [GERMAN]
                }
            ) {
                content {
                    content
                    key
                    name
                }
            }
        }
        aepsyApiEn {
            getMarkdownContents(
                input: {
                    keys: [
                        "CODE_OF_CONDUCT1"
                        "CODE_OF_CONDUCT2"
                        "CODE_OF_CONDUCT3"
                    ]
                    locales: [ENGLISH]
                }
            ) {
                content {
                    content
                    key
                    name
                }
            }
        }
        aepsyApiFr {
            getMarkdownContents(
                input: {
                    keys: [
                        "CODE_OF_CONDUCT1"
                        "CODE_OF_CONDUCT2"
                        "CODE_OF_CONDUCT3"
                    ]
                    locales: [FRENCH]
                }
            ) {
                content {
                    content
                    key
                    name
                }
            }
        }
        aepsyApiIt {
            getMarkdownContents(
                input: {
                    keys: [
                        "CODE_OF_CONDUCT1"
                        "CODE_OF_CONDUCT2"
                        "CODE_OF_CONDUCT3"
                    ]
                    locales: [ITALIAN]
                }
            ) {
                content {
                    content
                    key
                    name
                }
            }
        }
    }
`;

const CodeOfConduct = ({ data }) => {
    const { t } = useTranslation();
    const { currentLanguage } = useLocale();
    // Header mobile
    const static_images_sources = [
        data.headerMobileImage.childImageSharp.fluid,
        {
            ...data.headerDesktopImage.childImageSharp.fluid,
            media: `(min-width: 750px)`,
        },
    ];

    const markdownContentRes = getDataByLang({
        currentLanguage,
        key: 'getMarkdownContents',
        data,
    });

    const renderContent = () => {
        if (
            !markdownContentRes ||
            (markdownContentRes && !markdownContentRes.content)
        ) {
            return <></>;
        }

        return (
            <div className="ConductContent">
                {markdownContentRes.content.map((item, index) => (
                    <Section spacingBottom="s">
                        <OrderedCard highlightedText={index + 1} title="">
                            <Title size="l">{item.name}</Title>
                            <MarkdownText content={item.content} />
                        </OrderedCard>
                    </Section>
                ))}
            </div>
        );
    };

    return (
        <Layout>
            <Seo
                title={t('code.of.conduct.seo.title')}
                description={t('code.of.conduct.seo.description')}
            />
            <div className="global_theme-green">
                <HeaderNav theme="white" />
                <Section theme="light">
                    <HeaderMinimal
                        spacing="l"
                        theme="light"
                        title={t('code.of.conduct.header.title')}
                        btnPrimary={
                            <AnchorLink offset={() => 40} href="#CodeOfConduct">
                                <Button
                                    variant="primary"
                                    theme="white"
                                    label={t('learn.more')}
                                    size="l"
                                />
                            </AnchorLink>
                        }
                        imageSrc={static_images_sources}
                    />
                </Section>

                <div id="CodeOfConduct" className="CodeOfConduct">
                    {/* Header Quote */}
                    <Section
                        theme="dark"
                        container="large"
                        spacingTop="m"
                        spacingBottom="l"
                    >
                        <Title tag="p" size="xl" theme="white" noMargin>
                            <Trans
                                i18nKey={'code.of.conduct.statement'}
                                components={{ br: <br /> }}
                            />
                        </Title>
                    </Section>
                    <Section theme="light" spacingBottom="m" container="short">
                        {renderContent()}
                    </Section>
                    <Section theme="dark" spacing="m" container="large">
                        <div className="GetInTouch--wrapper">
                            <div className="GetInTouch--leftContent">
                                <Module>
                                    <Title size="l" align="center">
                                        {t(
                                            'code.of.conduct.get.in.touch.title'
                                        )}
                                    </Title>
                                    <Section spacing="xxs">
                                        <Text size="m" align="center">
                                            {t(
                                                'code.of.conduct.get.in.touch.subtitle'
                                            )}
                                        </Text>
                                    </Section>
                                    <a href="mailto:hey@aepsy.com">
                                        <Button
                                            variant="naked"
                                            align="center"
                                            iconArrow
                                            label={t(
                                                'code.of.conduct.get.in.touch.cta'
                                            )}
                                        />
                                    </a>
                                </Module>
                            </div>
                            <div className="GetInTouch--rightContent">
                                <Title size="xxl" font="alt" theme="white">
                                    {t(
                                        'code.of.conduct.get.in.touch.statement'
                                    )}
                                </Title>
                            </div>
                        </div>
                    </Section>
                    <Section container="large" spacing="m">
                        <VideoBackgroundWithContent
                            title={
                                <Title
                                    size="xl"
                                    theme="white"
                                    align="center"
                                    font="alt"
                                >
                                    <Trans
                                        i18nKey={
                                            'footer.learn.about.aepsy.title'
                                        }
                                        components={{ br: <br /> }}
                                    />
                                </Title>
                            }
                            subtitle={
                                <Text size="m" theme="white">
                                    {t('footer.learn.about.aepsy.subtitle')}
                                </Text>
                            }
                            cta={
                                <Section spacingTop="xs">
                                    <Link to="/about/">
                                        <Button
                                            theme="white"
                                            align="center"
                                            label={t('learn.about.aepsy')}
                                            isMobileFullsize
                                        />
                                    </Link>
                                </Section>
                            }
                        />
                    </Section>

                    {/* Footer */}
                    <Footer />
                </div>
            </div>
        </Layout>
    );
};

export default withLocale(CodeOfConduct);
