import React from 'react';
import './_Module.scss';

Module.defaultProps = {
    padding: 'm',
    radius: 'm',
};

export default function Module(props) {
    const mod_class = [
        'Module',
        props.className ? props.className : '',
        props.padding !== undefined ? 'padding-' + props.padding : '',
        props.radius !== undefined ? 'radius-' + props.radius : '',
        props.highlighted ? 'is-highlighted' : '',
        props.inactive ? 'is-inactive' : '',
        props.theme ? 'theme-' + props.theme : '',
    ].join(' ');

    const renderDOM = () => {
        if (props.title !== undefined) {
            return (
                <div onClick={() => props.onClick && props.onClick()}>
                    <div className="Module--title">{props.title}</div>
                    <div className={mod_class}>{props.children}</div>
                </div>
            );
        } else {
            return <div className={mod_class}>{props.children}</div>;
        }
    };

    return renderDOM();
}
